import _ from 'underscore/underscore-min';

const getDefaultLanguage = () => {
    let userLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
    if(!userLanguage){ userLanguage = 'en-US'; }
    let languageNormalized = userLanguage.split('-')[0].toLowerCase();

    return _.contains(['en'], languageNormalized) ? languageNormalized : 'en';
};

export default getDefaultLanguage;
