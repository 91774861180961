import { connect } from 'react-redux';

import React from 'react';
import { injectIntl } from 'react-intl';
import { Link, browserHistory } from 'react-router';
import {firebase} from '@firebase/app';
import '@firebase/auth';
import {toastr} from 'react-redux-toastr';

import ReCAPTCHA from 'react-google-recaptcha';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const captcha = React.createRef();


class CreateAccount extends React.Component {

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    createAccountWithEmailAndPassword(){
        var that = this;

        firebase.auth().createUserWithEmailAndPassword(
            this.refs.email.children[1].children[0].value, 
            this.refs.password.children[1].children[0].value
        )
            .catch((error) => {
                captcha.current.reset();
                if(error.code === 'auth/email-already-in-use'){
                    firebase.auth().fetchSignInMethodsForEmail(that.refs.email.children[1].children[0].value).then((result)=>{
                        toastr.error(that.t('error.title'), that.t('auth/account-exists-with-different-credential/' + result.toString()));
                    });
                }
                else {
                    toastr.error(this.t('error.title'), this.t(error.code));
                }
            });
    }

    componentDidMount(){
        this.checkUserConnected();
    }

    componentDidUpdate(){
        this.checkUserConnected();
    }

    checkUserConnected(){
        if(this.props.user){
            browserHistory.push('/');
        }
    }

    validateCatpcha(e) {
        e.preventDefault();
        captcha.current.execute();
    }

    render() {

        var email = null;

        return (
            <div className='CreateAccount animated flipInY'>

                <form onSubmit={this.validateCatpcha.bind(this)}>
                    <TextField margin="normal" fullWidth={true} type="email" ref="email" defaultValue={email} label={this.t('Email')} />
                    <TextField margin="normal" fullWidth={true} type="password" ref="password" label={this.t('Password')} />
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        fullWidth={true}
                    >
                        {this.t('Create account')}
                    </Button>
                </form>
                
                <div className="divider"><span>{this.t('Or')}</span></div>
                
                <p>{this.t('Already have an account?')} <Link to="/login">{this.t('Login here')}</Link></p>

                <ReCAPTCHA
                    ref={captcha}
                    size="invisible"
                    sitekey="6LfeoyIUAAAAALg753967VUp22Mwpxd7tlQFSzmZ"
                    onChange={this.createAccountWithEmailAndPassword.bind(this)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.app.user
    };
}

export default connect(mapStateToProps)(injectIntl(CreateAccount));
