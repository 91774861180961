import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router';

class NotFound extends React.Component {

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }
        
    render() {

        return (
            <div className='NotFound'>
                <div><img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" /></div>
                <h1>{this.t('not.found.404.title')}</h1>
                <p>{this.t('not.found.404')}</p>
                <Link to="/">&laquo; {this.t('not.found.404.link.text')}</Link>
            </div>
        );
    }
}

export default injectIntl(NotFound);
