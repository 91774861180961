import * as app from './AppConstants';
// import * as firebaseEvents from '../../utils/firebaseEvents';
// const Promise = require('es6-promise').Promise;

export function changeLocale(locale) {
    return {
        type: app.CHANGE_LOCALE,
        locale
    };
}

export function connectionState(state) {
    return {
        type: app.CONNECTED,
        state
    };
}

export function defineUser(user) {
    return {
        type: app.DEFINE_USER,
        user: user
    };
}

export function defineUserData(data) {
    return {
        type: app.DEFINE_USER_DATA,
        data: data
    };
}


export function showLoader() {
    return {
        type: app.SHOW_LOADER
    };
}

export function hideLoader() {
    return {
        type: app.HIDE_LOADER
    };
}