import { connect } from 'react-redux';
import React from 'react';
import { injectIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import {toastr} from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as firebaseEvents from '../../utils/firebaseEvents';
import MuiPhoneNumber from 'material-ui-phone-number';
import InputAdornment from '@material-ui/core/InputAdornment';


class Start extends React.Component {

    constructor(props){
        super(props);

        var savedData = {};
        if(localStorage.getItem('start')){
            savedData = JSON.parse(localStorage.getItem('start'));
        }

        this.state = {
            step: 1,
            firstName: savedData.firstName || '',
            lastName: savedData.lastName || '',
            phone: savedData.phone || '',
            affiliateId: ''
        };
    }


    validateStep1(){
        var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
        return (
            this.state.firstName !== '' &&
            this.state.lastName !== '' &&
            this.state.affiliateId !== '' &&
            this.state.phone !== '' &&
            regex.test(this.state.phone)
        );
    }

    saveData(name, value){
        var data = {};
        if(localStorage.getItem('start')){
            data = JSON.parse(localStorage.getItem('start'));
        }
        data[name] = value;
        localStorage.setItem('start', JSON.stringify(data));

    }
    

    render() {

        if(!this.props.user){
            if(process.env.NODE_ENV === 'production'){
                document.location.href='https://affiliates.planless.com/login';
            }
            else {
                document.location.href='http://localhost:3002/login';
            }
        }

        if(this.props.account && this.props.account.id){
            browserHistory.push('/dashboard');
            return null;
        }

        if(this.state.step === 1){
            
            return (
                <div className="Start step1">

                    <div>

                        <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
                        <h2>Planless Affiliate program</h2>
                        <h1>Tell us about yourself</h1>
                        <p>So we get to know you...</p>

                        <div className="inner">
                            <TextField 
                                fullWidth={true}
                                label="First name"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                value={this.state.firstName}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e)=>{
                                    this.saveData('firstName', e.target.value);
                                    this.setState({...this.state, firstName: e.target.value});
                                }}
                            />
                            <TextField 
                                fullWidth={true} 
                                label="Last name"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                value={this.state.lastName}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e)=>{
                                    this.saveData('lastName', e.target.value);
                                    this.setState({...this.state, lastName: e.target.value});
                                }}
                            />
                            <MuiPhoneNumber 
                                defaultCountry={'us'} 
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                label="Phone number"
                                value={this.state.phone}
                                onChange={(e)=>{
                                    e = e.replace('(','').replace(')','').replace('-','');
                                    this.saveData('phone', e);
                                    this.setState({...this.state, phone: e});
                                }}
                            />
                            <TextField 
                                fullWidth={true} 
                                label="Your affiliate link"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                value={this.state.affiliateId}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">https://www.planless.com?via=</InputAdornment>,
                                }}
                                onChange={(e)=>{
                                    var val = e.target.value.replace(/[^a-zA-Z]+/g,'').toLowerCase();

                                    this.saveData('affiliateId', val);
                                    this.setState({...this.state, affiliateId: val});
                                }}
                            />

                        </div>

                        <Button 
                            variant="contained" color="secondary" className="nextBtn" 
                            onClick={()=>{
                                if(this.validateStep1()){

                                    firebaseEvents.validateAffiliateId(this.state).then((res)=>{
                                        if(!res){
                                            toastr.error('This affiliate link is already taken.');
                                        }
                                    });
                                    
                                }
                                else {
                                    toastr.error('Please fill all the fields');
                                }
                            }}
                        >
                            START
                        </Button>
                    </div>

                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.app.user,
        account: state.app.account
    };
}

export default connect(mapStateToProps)(injectIntl(Start));
