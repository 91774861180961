import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';

import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import UserIcon from '@material-ui/icons/AccountCircle';

import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';


class HeaderUserAccount extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            userMenuOpen: false
        };
    }

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    openUserMenu(e){
        this.setState({
            userMenuOpen: true,
            anchorEl: e.currentTarget
        });
    }

    closeUserMenu(){
        this.setState({
            userMenuOpen: false,
            anchorEl: null
        });
    }

    getUserAvatar(){
        if(this.props.user && this.props.user.data && this.props.user.data.avatar){
            return <IconButton onClick={this.openUserMenu.bind(this)}><Avatar className="avatar" src={this.props.user.data.avatar} /></IconButton>;
        }
        return <IconButton onClick={this.openUserMenu.bind(this)}><Avatar className="avatar emptyAvatar"><UserIcon /></Avatar></IconButton>;
    }

    logOut(){
        firebaseEvents.logOut(this.t.bind(this));
    }

    render() {

        return (
            <div className='HeaderUserAccount'>
                {this.getUserAvatar()}
                <Menu
                    anchorEl={this.state.anchorEl}
                    className="userAccountIconMenu"
                    open={this.state.userMenuOpen}
                    keepMounted
                    onClose={this.closeUserMenu.bind(this)}
                >
                    <MenuItem onClick={()=>{ browserHistory.push('/my-account'); this.closeUserMenu(); }}><UserIcon /> {this.t('My account')}</MenuItem>
                    <MenuItem onClick={this.logOut.bind(this)} style={{color:'#cc0000'}}><LogoutIcon className="logoutIcon" /> {this.t('Log out')}</MenuItem>
                </Menu>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
        user: state.app.user
    };
}

export default connect(mapStateToProps)(injectIntl(HeaderUserAccount));
