import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { injectIntl } from 'react-intl';
import HeaderUserAccount from '../HeaderUserAccount/HeaderUserAccount';
import classnames from 'clsx';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CommissionsIcon from '@material-ui/icons/LocalAtm';
import PayoutsIcon from '@material-ui/icons/Payment';

class Header extends React.Component {

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    isRoute(route){
        var regex = new RegExp('^' + route);
        return (regex.test(this.props.routing.locationBeforeTransitions.pathname));
    }
        
    render() {

        return (
            <div className='Header'>
                <Link to="/dashboard" onClick={()=>this.setState({...this.state, navOpen: false})} className="logo">
                    <img src="../../../stylesheets/assets/logo-icon.svg?v=2" className="black" alt="logo" />
                </Link>

                <nav>
                    <Link 
                        key="dashboard" 
                        to="/dashboard" 
                        className={classnames({active: this.isRoute('/dashboard')})}
                    >
                        <DashboardIcon /> {this.t('Dashboard')}
                    </Link>
                    <Link 
                        key="commissions" 
                        to="/commissions" 
                        className={classnames({active: this.isRoute('/commissions')})}
                    >
                        <CommissionsIcon /> {this.t('Commissions')}
                    </Link>
                    <Link 
                        key="payouts" 
                        to="/payouts" 
                        className={classnames({active: this.isRoute('/payouts')})}
                    >
                        <PayoutsIcon /> {this.t('Payouts')}
                    </Link>
                </nav>
                
                <HeaderUserAccount onTouchTap={()=>this.setState({...this.state, navOpen: false})} />
                
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
        user: state.app.user,
        routing: state.routing
    };
}

export default connect(mapStateToProps)(injectIntl(Header));
