import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as firebaseEvents from '../../utils/firebaseEvents';
import {bindActionCreators} from 'redux';
import * as appactions from '../App/AppActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class MyAccount extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            email: this.getUserData('email') || '',
            definedUserEmail: false,
            displayName: this.getUserData('displayName'),
            props: this.props
        };
    }

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    componentDidUpdate(){
        if(!this.state.definedUserEmail && this.getUserData('email')){
            this.setState(
                {
                    ...this.state,
                    email: this.getUserData('email'),
                    definedUserEmail: true
                }
            );
        }
    }

    getUserData(field){
        if(this.props.user && this.props.user.data && this.props.user.data[field]){
            return this.props.user.data[field];
        }
        return '';
    }
    /* eslint-disable no-unused-react-component-methods/no-unused-react-component-methods */
    static getDerivedStateFromProps(nextProps, state) {
        if(nextProps !== state.props && nextProps.user && nextProps.user.data){
            return {
                ...state,
                displayName: nextProps.user.data.displayName,
                props: nextProps
            };
        }
        return null;
    }
    /* eslint-enable no-unused-react-component-methods/no-unused-react-component-methods */
    
    changeEmail(event){
        this.setState(
            {...this.state, email: event.target.value}
        );
    }

    updateEmail(){
        firebaseEvents.changeAccountEmail(
            this.state.email,
            this.t.bind(this)
        );
    }

    updatePwd(){
        firebaseEvents.resetPassword(
            this.getUserData('email'), 
            this.t.bind(this), 
            ()=>{ return false; }
        );
    }

        
    render() {

        return (
            <div className='MyAccount'>
                <h2>{this.t('My Account')}</h2>
                <div className="content">

                    <div className="section">

                        <TextField 
                            type="email" 
                            ref="email" 
                            margin="normal"
                            label={this.t('My email')} 
                            value={this.state.email} 
                            onChange={this.changeEmail.bind(this)}
                            fullWidth={true}
                        />

                        <Button 
                            variant="contained"
                            className="changeBtn"
                            color="primary"
                            onClick={this.updateEmail.bind(this)}
                        >
                            {this.t('Change my account email')} 
                        </Button>

                    </div>

                    <div className="section">

                        <TextField 
                            type="password"
                            className="passField"
                            ref="pwd" 
                            margin="normal"
                            label={this.t('My password')} 
                            defaultValue='qwertyuiop' 
                            fullWidth={true}
                            disabled={true}
                        />

                        <Button 
                            variant="contained"
                            className="changeBtn"
                            color="primary"
                            onClick={this.updatePwd.bind(this)}
                        >
                            {this.t('Change my password')} 
                        </Button>

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.app.user,
        account: state.app.account,
        userAccounts : state.app.userAccounts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyAccount));
