import * as constants from './AppConstants';
import getDefaultLanguage from '../../utils/defaultLanguage';
import versionJson from '../../../version.json';

const app = (state = {
    locale: getDefaultLanguage(),
    user:undefined,
    hasConnection: true,
    loader: false,
    version: versionJson.version,
}, action) => {
    switch (action.type) {

    case constants.CHANGE_LOCALE:
        return { ...state, locale: action.locale };

    case constants.CONNECTED:
        return { ...state, hasConnection: action.state };

    case constants.DEFINE_USER:
        return { ...state, user: action.user };

    case constants.DEFINE_USER_DATA:
        return { ...state, user: {...state.user, data: action.data} };

    case constants.SHOW_LOADER:
        return { ...state, loader: true };

    case constants.HIDE_LOADER:
        return { ...state, loader: false };

    default:
        return state;
    }
};

export default app;


