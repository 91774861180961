import React from 'react';
import { connect } from 'react-redux';
import {injectIntl} from 'react-intl';
// import { Link, browserHistory } from 'react-router';
// import * as firebaseEvents from '../../utils/firebaseEvents';
import {bindActionCreators} from 'redux';
import * as appactions from '../App/AppActions';
import Button from '@material-ui/core/Button';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

class Dashboard extends React.Component {

    constructor(props){
        super(props);

        this.state = {showRequestPayout:false};
    }

    render() {

        var nbCommissions = 0, balance = 0, pending = 0;
        if(this.props.user?.data?.commissions){
            nbCommissions = Object.keys(this.props.user.data.commissions).length;

            for(var comId in this.props.user.data.commissions){
                if(!this.props.user.data.commissions[comId].paid){
                    if(moment.unix(this.props.user.data.commissions[comId].createdAt).isAfter(moment().subtract(30,'days'))){
                        pending += this.props.user.data.commissions[comId].value;
                    }
                    else {
                        balance += this.props.user.data.commissions[comId].value;
                    }
                }
            }
        }

        return (
            <div className="Dashboard">
                <div className="overview">
                    <div className="box numbers">
                        <div>
                            <h5>Visits</h5>
                            <h4>{this.props.user?.data?.visits || 0}</h4>
                        </div>
                        <div>
                            <h5>Trials</h5>
                            <h4>{this.props.user?.data?.trials || 0}</h4>
                        </div>
                        <div>
                            <h5>Affiliates</h5>
                            <h4>{this.props.user?.data?.affiliates || 0}</h4>
                        </div>
                        <div>
                            <h5>Commissions</h5>
                            <h4>{nbCommissions}</h4>
                        </div>
                    </div>
                    <div className="box balance">
                        <div>
                            <h3>Payable balance</h3>
                            <h1>{balance.toFixed(2)} <span>USD</span></h1>
                        </div>
                        <div>
                            <h4>Pending balance</h4>
                            <h5>{pending.toFixed(2)} <span>USD</span></h5>
                        </div>
                        <div>
                            <Button variant="contained" color="primary" disabled={(balance === 0)}
                                onClick={()=>this.setState({...this.state, showRequestPayout:true})}
                            >
                                Request payout
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="box link">
                    <div>
                        <p>Your affiliate link:</p>
                        <div>
                            <input readOnly value={'https://www.planless.com?via=' + this.props.user?.data?.link} />
                            <a onClick={() => {
                                const el = document.createElement('textarea');
                                el.value = 'https://www.planless.com?via=' + this.props.user?.data?.link;
                                document.body.appendChild(el);
                                el.select();
                                document.execCommand('copy');
                                document.body.removeChild(el);
                                toastr.success('Link copied to your clipboard');
                            }}>Copy</a>
                        </div>
                        <p>You can link to any page of our website and add <span>?via={this.props.user?.data?.link}</span> at the end of the URL.</p>
                    </div>
                    <div>
                        <p>Recruit other affiliates:</p>
                        <div>
                            <input readOnly value={'https://affiliates.planless.com/create-account?via=' + this.props.user?.data?.link} />
                            <a onClick={() => {
                                const el = document.createElement('textarea');
                                el.value = 'https://affiliates.planless.com/create-account?via=' + this.props.user?.data?.link;
                                document.body.appendChild(el);
                                el.select();
                                document.execCommand('copy');
                                document.body.removeChild(el);
                                toastr.success('Link copied to your clipboard');
                            }}>Copy</a>
                        </div>
                    </div>
                </div>

                <div className="box description">
                    <h1>Planless Affiliate program conditions</h1>
                    <p>Promote Planless to your network and get commissionned on every referral that converts to a paying user and every time he renews...for ever!</p>
                    <p>When someone accesses our website with your affiliate link (above), we associate this visitor to your affiliate account.</p>
                    <p className="bold big">You'll earn 20% comission on every payment we get from this referral and 30% if you use your balance towards your own Planless subscription</p>
                    <p className="bold big">You'll also earn 20% comission on every commission generated by an affiliate you recruited.</p>
                    <p>You can see above the number of Visitors we got through your affiliate link, the number of trials that have been started, the number of affiliates you recruited and the number of commissions you got.</p>
                    <p>&nbsp;</p>
                    <p className="bold">Example 1 (direct referral):</p>
                    <p>Someone visited Planless website through your link and subscribes to a yearly plan for 20 users.</p>
                    <p>You will get a commission of <b>1,152 USD</b>. When this user will renew his yearly subscription, you'll get your commission again.</p>
                    <p>&nbsp;</p>
                    <p className="bold">Example 2 (through a recruited affiliate):</p>
                    <p>Someone visited Planless website through the link of one of the affiliates you recruited and subscribes to a yearly plan for 20 users.</p>
                    <p>Your affiliate will get the 1,152 USD commission and you will get <b>230.40 USD</b> commission. </p>
                    <p>&nbsp;</p>
                    <p>Commissions are pending during 30 days in case the customer asks for a refund during this period. Your commissions become available for payout after this 30 days carrency period.</p>
                    <p>You can request payouts as bank transfer (against invoice), Gift cards, or as credit note on your own Planless subscription (in that case you get 1.5x the payable balance).</p>
                </div>
                
                <Dialog fullWidth maxWidth="md" onClose={()=>this.setState({...this.state, showRequestPayout:false})} open={this.state.showRequestPayout}>
                    <DialogTitle id="simple-dialog-title">Request a payout of your commissions</DialogTitle>
                    <div className="payoutDialog">
                        <div>
                            <h4>Option 1</h4>
                            <h3>Get <b>{balance.toFixed(2)} USD</b> in bank transfer</h3>
                            <p>Send us your payout request at <a href="mailto:affiliates@planless.io">affiliates@planless.io</a> with an invoice of {balance.toFixed(2)} USD. The invoice recipient data should be:</p>
                            <code>
                                PLANLESS LDA<br/>
                                Avenida David Mourão-Ferreira 14 8A<br/>
                                1750-204 Lisboa (Portugal)<br/>
                                NIF: 514551852
                            </code>
                        </div>
                        <div>
                            <h4>Option 2</h4>
                            <h3>Get <b>{balance.toFixed(2)} USD</b> in Gift card</h3>
                            <p>Send us your payout request at <a href="mailto:affiliates@planless.io">affiliates@planless.io</a> mentionning what type of gift card below you'd like to receive:</p>
                            <img src="../../../stylesheets/assets/gift-cards.jpg" />
                        </div>
                        <div>
                            <h4>Option 3</h4>
                            <h3>Get <b>{(balance*1.5).toFixed(2)} USD</b> as Planless credits</h3>
                            <p>Send us your payout request at <a href="mailto:affiliates@planless.io">affiliates@planless.io</a> mentionning your Planless account email address.</p>
                            <p>Once validated, you'll get a creadit of {(balance*1.5).toFixed(2)} USD for the payment of your subscription.</p>
                        </div>
                    </div>
                </Dialog>
                
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.app.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard));
