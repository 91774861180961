import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import classnames from 'clsx';
import { IntlProvider } from 'react-intl';
import { browserHistory } from 'react-router';
import * as appactions from './AppActions';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as firebaseEvents from '../../utils/firebaseEvents';
import $ from 'jquery';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1E4FA2',
            light: '#243758'
        },
        secondary: {
            main: '#F0562C'
        },
        text: {
            primary: '#333'
        }
    },
    typography: {
        fontFamily: ['"Open Sans"', 'sans-serif']
    }
});

var loaderHidden = false;

class App extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            showOnboarding: false,
            showApp: false,
            showUpdate: 'no',
            showDiscountFirstDay: true
        };
    }

    componentDidMount(){
        var that = this;

        window.onload = function(){
            that.setState({...that.state, showApp: true});
        };

        firebaseEvents.defineFirebaseEvents(this);

        setInterval(this.checkVersion.bind(this), 10000);

        this.checkVersion();

        const getCookie = function(name) {
            var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) return match[2];
        };

        if(this.props.router && this.props.router.location && this.props.router.location.query && this.props.router.location.query.via && !getCookie('pl_aid')){
            const domain = (process.env.NODE_ENV !== 'production')?'localhost':'.planless.com';
            document.cookie = 'pl_aid=' + this.props.router.location.query.via + ';expires=' + new Date().setMonth(new Date().getMonth() + 3) + ';domain=' + domain + ';path=/';
            $.ajax('https://us-central1-planless-affiliates.cloudfunctions.net/addVisit?link=' + this.props.router.location.query.via);
        }
    }

    checkVersion(){
        if(process.env.NODE_ENV === 'production'){
            $.getJSON('https://affiliates.planless.com/version.json', ( data )=>{
                if(data.version !== this.props.app.version){
                    var setTo = 'yes';
                    if(data.force === 'true'){
                        setTo = 'force';
                    } 
                    this.setState({...this.state, showUpdate: setTo});
                }
            });
        }
    }


    getDisconnected(){
        if(!this.props.app.hasConnection){
            return (
                <div className="disconnected">
                    <strong>You are disconnected!</strong>
                    You can continue to work but your changes would only be synced when you go back online.
                </div>
            );
        }
        return null;
    }

    getLoader(){
        return (
            <div className={classnames('loader', {show: this.props.app.loader})}>
                <img src="../../../stylesheets/assets/logo-icon.svg?v=2" alt="Loader icon" />
            </div>
        );
    }

    getVersionChange(){
        if(this.state.showUpdate !== 'no'){

            var force = (this.state.showUpdate === 'force');

            return (
                <div className={classnames('versionUpdate', {force})}>
                    <img src="../../../stylesheets/assets/logo.svg?v=2" className="logo" alt="logo" />
                    A new version of Planless Affiliate system is available. Please reload your page.
                </div>
            );
        }

        return null;
    }

    render() {

        if(this.state.showOnboarding){
            $('#loader').css('opacity', 0);
            setTimeout(()=>{ $('#loader').css('display', 'none'); }, 2000);
            loaderHidden = true;
            if(document.location.pathname !== '/start'){
                browserHistory.push('/start');
            }
        }

        if(this.props.app.user === undefined){
            return null;
        }

        let locale = this.props.app.locale || 'en',
            translations = require('../../../locales/' + locale + '.json');
        
        if(
            (!loaderHidden && this.props.children.type.displayName === 'injectIntl(StructOff)') ||
            (!loaderHidden && this.props.children.type.displayName === 'injectIntl(Quote)') ||
            (!loaderHidden&& this.props.app.user && this.props.app.user.data)
        ){
            $('#loader').css('opacity', 0);
            setTimeout(()=>{ $('#loader').css('display', 'none'); }, 2000);
            loaderHidden = true;
        }


        return (
            <IntlProvider locale={locale} messages={translations}>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className={classnames('App', {active: this.props.app.account && this.props.app.user && this.props.app.user.data})}>
                            <div className={classnames('AppContent', {hide: !this.state.showApp})} style={{opacity:0}}>
                                {this.props.children}
                                {this.getDisconnected()}
                            </div>

                            {this.getLoader()}
                            {this.getVersionChange()}

                        </div>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </IntlProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        parentChildIds: state.app.parentChildIds,
        orderedTaskList: state.app.orderedTaskList,
        routing: state.routing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);