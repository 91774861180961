import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import {reducer as toastrReducer} from 'react-redux-toastr';

import app from './views/App/AppReducer';

const reducers = combineReducers({
    routing: routerReducer,
    toastr: toastrReducer,
    app
});

export default reducers;
