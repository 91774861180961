/* eslint-disable */
/**
 * Middleware to avoid running an action if the state won't change.
 */

const actionsIgnore = store => next => action => {
    return next(action);
};

export default actionsIgnore;
