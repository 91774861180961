import {firebase} from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';
import '@firebase/messaging';
import { browserHistory } from 'react-router';
import {toastr} from 'react-redux-toastr';

var Promise = require('es6-promise').Promise;

var database,
    appComp,
    authUser;


firebase.initializeApp({
    projectId: process.env.FIREBASE_PROJECTID,
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.FIREBASE_DATABASEURL,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID
});

database = firebase.database();


var db = {
    v: 'value',
    r: (p)=>database.ref(p),
    set: (p, v)=>db.r(p).set(v),
    equal: (p, o, v, f)=>db.r(p).orderByChild(o).equalTo(v).once(db.v).then(f),
    order: (p, o, s, f)=>db.r(p).orderByChild(o).startAt(s).once(db.v).then(f),
    update: (p, u)=>db.r(p).update(u),
    push: (p, d)=>db.r(p).push(d),
    pushkey: (p)=>db.push(p).key,
    off: (p)=>db.r(p).off(db.v),
    on: (p, f)=>db.r(p).on(db.v, f),
    once: (p)=>db.r(p).once(db.v),
    remove: (p)=>db.r(p).remove()
};

export function defineFirebaseEvents(appComponent){
    appComp = appComponent;

    // Auth state change handler for login/logout
    firebase.auth().onAuthStateChanged((user) => {
        
        if(process.env.NODE_ENV === 'production'){
            authUser = user;
        }
        else {
            authUser = user;
            // authUser = {...user, uid:'U8hW66x60TeHW9dFJuaK3gyFyxX2',email:'mark@flocc.co'};
            // authUser = {...user, uid:'3tyftPbxBzOZNROY3Jk8jtg8Can2',email:'angela@designontap.com'};  
            
            // authUser = {...user, uid:'3tyftPbxBzOZNROY3Jk8jtg8Can2',email:'angela@designontap.com'};  
        }

        appComponent.props.actions.defineUser(authUser);

        if(authUser){
            checkUserHasAccount(authUser);                
            browserHistory.push(appComponent.props.routing.locationBeforeTransitions.pathname + appComponent.props.routing.locationBeforeTransitions.search);
        }
    });


    // handle disconnection
    
    db.on('.info/connected', function(snap) {

        if (snap.val() === true && window.navigator.onLine) {
            appComp.props.actions.connectionState(true);
        } else if(snap.val() === false && !window.navigator.onLine) {
            appComp.props.actions.connectionState(false);
        }

    });
}

const getCookie = function(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
};

export function checkUserHasAccount(authUser){

    var userId = null;
    // if user doesn't have an associated account, create one
    db.equal('/users', 'uid', authUser.uid, (user) => {

        if(!user.val()){
            db.equal('/users', 'email', authUser.email, (u) => {
                userId = null;

                if(!u.val()) {
                    userId = db.pushkey('/users');

                    db.set('/users/' + userId, {
                        uid: authUser.uid,
                        email: authUser.email
                    }).then (()=>{
                        setUserChangeListener(userId); //, authUser
                    });

                    if(getCookie('pl_aid')){
                        db.equal('/users', 'link', getCookie('pl_aid'), (user) => {
                            if(user.val()){
                                var affCount = user.val()[Object.keys(user.val())[0]].affiliates || 0;
                                affCount++;
                                var updates = {};
                                updates['/users/' + Object.keys(user.val())[0] + '/affiliates'] = affCount;
                                updates['/affToAff/' + userId] = Object.keys(user.val())[0];
                                db.update(undefined, updates);
                            }
                        });
                    }
                }
                else {
                    userId = Object.keys(u.val())[0];

                    setUserChangeListener(userId); //, authUser
                }
            });
        }
        else {
            userId = Object.keys(user.val())[0];

            setUserChangeListener(userId); //, authUser
        }
    });
}

export function setUserChangeListener(userId){ //, authUser
    
    db.on('/users/' + userId, (user) => {
        var userData = user.val();
        userData.userId = userId;


        if(!userData.link){        
            appComp.setState({
                ...appComp.state,
                showOnboarding: true
            });   
        }

        appComp.props.actions.defineUserData(userData);
    });
}

export function resetPassword(email,intl,closeAction){

    firebase.auth().sendPasswordResetEmail(email).then(
        ()=>{
            toastr.success(intl('We sent you an email to reset your password!'));
            closeAction();
        },
        (error)=>{
            toastr.error(intl(error.message));
        }
    );
}


export function changeAccountEmail(email, intl){
    var t = intl;
    firebase.auth().currentUser.updateEmail(email).then(function() {
        db.set('/users/' + appComp.props.app.user.data.userId + '/email', email);
        document.location.reload();
    }, function(error) {
        toastr.error(t(error.message));
    });
}

export function logOut(intl){
    var t = intl;
    
    appComp.props.actions.defineUser(null);

    firebase.auth().signOut().then(()=>{
        
        appComp.props.actions.defineUser(null);
        appComp.props.actions.defineUserData({userId:null});

        for(var i in localStorage){
            if(i.indexOf('firebase:authUser') !== -1){
                localStorage.removeItem(i);
            }
        }
    }).catch(function(error) {
        toastr.error(t(error.message));
    });
}

export function updateMemberData(){
    console.log('updateMemberData');
}

export function validateAffiliateId(data){
    return new Promise((resolve) => { 

        if(data.affiliateId.length < 3){
            resolve(false); 
        }
        else {

            db.equal('/users', 'link', data.affiliateId, (user) => {
                if(user.val()){
                    resolve(false); 
                }
                else {
                    var updates = {};
                    updates['/users/' + appComp.props.app.user.data.userId + '/link']=data.affiliateId;
                    updates['/users/' + appComp.props.app.user.data.userId + '/firstName'] = data.firstName;
                    updates['/users/' + appComp.props.app.user.data.userId + '/lastName'] = data.lastName;
                    updates['/users/' + appComp.props.app.user.data.userId + '/phone'] = data.phone;

                    db.update(undefined, updates).then(()=>{
                        appComp.setState({
                            ...appComp.state,
                            showOnboarding: false
                        });

                        browserHistory.push('/dashboard');

                        resolve(true); 
                    });
                }
            });

        }
        
    });
}
