import React from 'react';
import { connect } from 'react-redux';
import {injectIntl} from 'react-intl';
// import { Link, browserHistory } from 'react-router';
// import * as firebaseEvents from '../../utils/firebaseEvents';
import {bindActionCreators} from 'redux';
import * as appactions from '../App/AppActions';
import _ from 'underscore';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class Commissions extends React.Component {

    render() {

        var list = [];

        if(this.props.user?.data?.commissions){
            var temp = _.sortBy(this.props.user?.data?.commissions, 'createdAt');
            temp.forEach(item=>{
                var status = <span className="confirmed">Confirmed</span>;
                if(item.paid){
                    status = <span className="paid">Paid</span>;
                }
                else if(moment.unix(item.createdAt).isAfter(moment().subtract(30,'days'))){
                    status = <span className="pending">Pending</span>;
                }
                list.push(
                    <TableRow>
                        <TableCell>{moment.unix(item.createdAt).format('LL')}</TableCell>
                        <TableCell>{(item.type === 'direct')?'Direct referral':'Through Affiliate'}</TableCell>
                        <TableCell>{item.value.toFixed(2)} USD</TableCell>
                        <TableCell>{status}</TableCell>
                    </TableRow>
                );
            });
        }

        return (
            <div className="Commissions">
                <div className="box">
                    {(list.length)?(
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Amount (USD)</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list}
                            </TableBody>
                        </Table>
                    ):(
                        <span>No Commissions have been found. <b>Keep referring more people!</b></span>
                    )}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.app.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Commissions));
