import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Header from '../Header/Header';

class StructOn extends React.Component {

    getLoader(){
        if(!this.props.account && !this.props.user){
            return (
                <div className="globalLoader">
                    <img src="../../../stylesheets/assets/logo-icon.svg?v=2" alt="Planless loader icon" />
                </div>
            );
        }
        return null;
    }
        
    render() {

        return (
            <div className='StructOn'>
                <Header />
                <div>
                    {this.props.children}
                </div>
                {this.getLoader()}

            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        app: state.app,
        user: state.app.user
    };
}

export default connect(mapStateToProps)(injectIntl(StructOn));
