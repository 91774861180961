import React from 'react';
import { injectIntl } from 'react-intl';
import Paper from '@material-ui/core/Paper';


class StructOff extends React.Component {

    render() {

        return (
            <div className='StructOff'>
                <Paper elevation={9} className="delay-1s">
                    <div>
                        <h2>Planless Affiliate program</h2>
                        <h1>Get you share of the revolution!</h1>
                        <p>Planless is changing the way teams plan & organize their work and we want YOU to be part of this revolution and get your share of it.</p>
                        <p>Help us get the world to know about AI-powered Project planning & Resource allocation and get comissionned for every team you reffered.</p>
                        <p className="bold">Earn up to 30% recurring commission for every new team that you sent to us. Lifetime!</p>
                        <p>Get started in 2 minutes...</p>
                    </div>
                    <div>
                        <img src="../../../stylesheets/assets/logo.svg?v=2" alt="Planless logo" />
                        {this.props.children}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default injectIntl(StructOff);
