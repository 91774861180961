import React from 'react';
import { render } from 'react-dom';
import Store from './configureStore';


// Application Main CSS
require('../stylesheets/main.scss');

function run() {
    render(<Store />, document.getElementById('root'));
}

// Check if polyfill required
if (!global.Intl) {
    require.ensure([
        'intl',
        'intl/locale-data/jsonp/en.js'
    ], function (require) {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        run();
    });
}
else {
    run();
}

