import React from 'react';
import { Router, Route, IndexRedirect, browserHistory } from 'react-router';
import { compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducersExport';
import { syncHistoryWithStore } from 'react-router-redux';
import { actionsIgnore } from './utils/middlewares';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect';
import ReduxToastr from 'react-redux-toastr';

import App from './views/App/App';
import StructOff from './views/StructOff/StructOff';
import StructOn from './views/StructOn/StructOn';
import NotFound from './views/NotFound/NotFound';
import Login from './views/Login/Login';
import CreateAccount from './views/CreateAccount/CreateAccount';
import AuthAction from './views/AuthAction/AuthAction';
import MyAccount from './views/MyAccount/MyAccount';
import ResetPassword from './views/ResetPassword/ResetPassword';
import Start from './views/Start/Start';
import Dashboard from './views/Dashboard/Dashboard';
import Commissions from './views/Commissions/Commissions';
import Payouts from './views/Payouts/Payouts';

var createStoreWithMiddleware, prevUrl;

if(process.env.NODE_ENV !== 'production'){
    /* eslint-disable no-underscore-dangle */
    if(window.__REDUX_DEVTOOLS_EXTENSION__){
        createStoreWithMiddleware = compose(
            applyMiddleware(thunk, actionsIgnore),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )(createStore);
    }
    else {
        createStoreWithMiddleware = compose(
            applyMiddleware(thunk, actionsIgnore)
        )(createStore);
    }
    /* eslint-enable */
}
else {
    createStoreWithMiddleware = compose(
        applyMiddleware(thunk, actionsIgnore)
    )(createStore);
}

const store = createStoreWithMiddleware(rootReducer);
const history = syncHistoryWithStore(browserHistory, store);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducersExport', () => {
        const nextRootReducer = require('./reducersExport');
        store.replaceReducer(nextRootReducer);
    });
}


class Store extends React.Component {

    onChangeRoute(){
        if(window.analytics){
            window.analytics.page();
        }
        if(
            document.location.pathname && 
            prevUrl && 
            document.location.pathname.indexOf(prevUrl) === -1 && 
            prevUrl.indexOf(document.location.pathname) === -1
        ){
            if(
                !(
                    document.location.pathname.indexOf('/tasks/') !== -1 &&
                    prevUrl.indexOf('/tasks/') !== -1
                )
            ){
                window.scrollTo(0,0);
            }
        }  
        prevUrl = document.location.pathname;
    }

    render () {
        var result = (
            <Provider store={store}>
                <div className="Store">
                    <Router history={history} onUpdate={this.onChangeRoute}>
                        <Route path="/" component={App}>
                            <Route component={UserIsAuthenticated(StructOn)}>
                                <IndexRedirect to="/dashboard" />
                                <Route path='dashboard' component={Dashboard} />
                                <Route path='commissions' component={Commissions} />
                                <Route path='payouts' component={Payouts} />
                                <Route path='my-account' component={MyAccount} />
                            </Route>
                            <Route component={StructOff}>
                                <Route path='login' component={Login}>
                                    <Route path='reset-password' component={ResetPassword}/>
                                </Route>
                                <Route path='create-account' component={CreateAccount} />
                                <Route path='authAction' component={AuthAction} />
                            </Route>
                            <Route path="start" component={Start} />
                            <Route path="*" component={NotFound} />
                        </Route>
                    </Router>
                    
                    <ReduxToastr
                        timeOut={10000}
                        newestOnTop={false}
                        position="top-right"
                        transitionIn="bounceInDown"
                        transitionOut="bounceOutUp"
                        progressBar
                    />
                </div>
            </Provider>
        );

        return result;

    }
}
const UserIsAuthenticated = connectedRouterRedirect({
    authenticatedSelector: state => state.app.user !== null,
    redirectPath: '/login',
    allowRedirectBack:true
});

export default Store;
