import React from 'react';
import { Link, browserHistory } from 'react-router';
import { injectIntl } from 'react-intl';
import {toastr} from 'react-redux-toastr';
import {firebase} from '@firebase/app';
import '@firebase/auth';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import $ from 'jquery';

class AuthAction extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            content: '',
            linkText: '',
            showPassForm: false
        };
    }

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    getParameterByName(param){
        if(this.props.location.query && this.props.location.query[param]){
            return this.props.location.query[param];
        }
        return null;
    }

    componentDidMount(){

        var mode = this.getParameterByName('mode'),
            actionCode = this.getParameterByName('oobCode');

        switch (mode) {
        case 'resetPassword':
            this.handleResetPassword(actionCode);
            break;
        case 'recoverEmail':
            this.handleRecoverEmail(actionCode);
            break;
        case 'verifyEmail':
            this.handleVerifyEmail(actionCode);
            break;
        case 'login':
            this.handleLogin(actionCode);
            break;
        default:
            this.setState({content: this.t('Something went wrong!')});
        }
    }

    handleLogin(token){
        this.setState({content: 'Please wait while we try to log you in...'});
        firebase.auth().signOut().then(()=>{
            for(var i in localStorage){
                if(i.indexOf('firebase:authUser') !== -1){
                    localStorage.removeItem(i);
                }
            }
            $.ajax({
                url: 'https://ot.planless.com/checkLoginToken',
                method: 'POST',
                data: {
                    token: token
                },
                async: true,
                crossDomain: true
            })
                .done((result)=>{
                    
                    firebase.auth().signInWithCustomToken(result.token).then(()=>{
                        browserHistory.push('/login');
                    }).catch(function(error) {
                        toastr.error(error.message);
                        browserHistory.push('/login');
                    });
                })
                .fail((error)=>{
                    toastr.error(error.responseJSON.error);
                    browserHistory.push('/login');
                });

        });
    }

    changePasswordForm(){
        if(this.state.showPassForm){
            return (
                <form onSubmit={this.updatePwd.bind(this)}>
                    <TextField 
                        type="password" 
                        label={this.t('Please enter your new password:')} 
                        ref="password"
                        margin="normal"
                        fullWidth={true}
                    />

                    <Button 
                        variant="contained"
                        className="changeBtn"
                        color="primary"
                        type="submit"
                    >
                        {this.t('Change my password')} 
                    </Button>
                </form>
            );
        }
        return null;
    }

    updatePwd(e){
        e.preventDefault();
        var actionCode = this.getParameterByName('oobCode'),
            accountEmail,
            newPassword = this.refs.password.children[1].children[0].value,
            that = this;

        firebase.auth().verifyPasswordResetCode(actionCode).then(function(email) {
            accountEmail = email;

            // Save the new password.
            firebase.auth().confirmPasswordReset(actionCode, newPassword).then(function() {
                that.setState({
                    content: that.t('Your password has been changed!'),
                    linkText: 'Go to Planless.io',
                    showPassForm: false
                });
                firebase.auth().signInWithEmailAndPassword(accountEmail, newPassword);
            }).catch(function(error) {
                toastr.error(that.t('error.title'), that.t(error.code));
            });
        }).catch(function() {
            that.setState({content: that.t('There was an error on changing your password! Please try again.')});
        });
    }

    handleResetPassword(actionCode){
        
        var that = this;

        if(!actionCode){
            that.setState({
                content: that.t('There was an error on changing your password! Please try again.'),
                linkText: that.t('Login')
            });
        }
        else {
            firebase.auth().verifyPasswordResetCode(actionCode).then(function() {
                that.setState({
                    content: '',
                    linkText: '',
                    showPassForm: true
                });
            }).catch(function() {
                that.setState({
                    content: that.t('There was an error on changing your password! Please try again.'),
                    linkText: that.t('Login')
                });
            });
        }
    }

    handleRecoverEmail(actionCode){
        var restoredEmail = null;
        var that = this;

        if(!actionCode){
            that.setState({
                content: that.t('There was an error recovering your email! Please try again.'),
                linkText: that.t('Login')
            });
        }
        else {
            firebase.auth().checkActionCode(actionCode).then(function(info) {
                restoredEmail = info['data']['email'];

                return firebase.auth().applyActionCode(actionCode);
            }).then(function() {
                that.setState({
                    content: that.t('Your email has been reverted to', {email: restoredEmail}),
                    linkText: that.t('Login')
                });
            }).catch(function() {
                that.setState({
                    content: that.t('There was an error recovering your email! Please try again.'),
                    linkText: that.t('Login')
                });
            });
        }
    }

    handleVerifyEmail(actionCode){
        var that = this;
        if(!actionCode){
            that.setState({
                content: that.t('There was an error verifing your email! Please try again.'),
                linkText: that.t('Login')
            });
        }
        else {
            firebase.auth().applyActionCode(actionCode).then(function() {
                that.setState({
                    content: that.t('Your email has been verified!'),
                    linkText: that.t('Login')
                });
                browserHistory.push('/login');
                setTimeout(()=>{ document.location.reload(); }, 300);

            }).catch(function() {
                that.setState({
                    content: that.t('There was an error verifing your email! Please try again.'),
                    linkText: that.t('Login')
                });
            });
        }
    }

    getLink(){
        if(this.state.linkText !== ''){
            return <Link to="/login">{this.state.linkText}</Link>;
        }
        return null;
    }


    render() {

        return (
            <div className="AuthAction">
                <div>{this.state.content}</div>
                {this.changePasswordForm()}
                {this.getLink()}
            </div>
        );
    }
}

export default injectIntl(AuthAction);
