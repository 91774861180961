import { connect } from 'react-redux';

import React from 'react';
import { injectIntl } from 'react-intl';
import { Link, browserHistory } from 'react-router';
import {firebase} from '@firebase/app';
import '@firebase/auth';
import {toastr} from 'react-redux-toastr';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class Login extends React.Component {

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    signInWithEmailAndPassword(e){
        e.preventDefault();

        firebase.auth().signInWithEmailAndPassword(
            this.refs.email.children[1].children[0].value, 
            this.refs.password.children[1].children[0].value
        ).catch((error) => {
            toastr.error(this.t('error.title'), this.t(error.code));
        });
    }

    componentDidMount(){
        this.checkUserConnected();
    }

    componentDidUpdate(){
        this.checkUserConnected();
    }

    checkUserConnected(){
        if(this.props.user){
            if(this.props.routing.locationBeforeTransitions.query.redirect){
                browserHistory.push(
                    this.props.routing.locationBeforeTransitions.query.redirect
                );
            }
            else {
                browserHistory.push('/');
            }
        }
    }

    render() {

        return (
            <div className='Login animated flipInY'>

                <form onSubmit={this.signInWithEmailAndPassword.bind(this)}>
                    <TextField margin="dense" fullWidth={true} type="email" ref="email" label={this.t('Email')} />
                    <TextField margin="dense" fullWidth={true} type="password" ref="password" label={this.t('Password')} />

                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        fullWidth={true}
                    >
                        {this.t('Sign in')}
                    </Button>

                    <p>Forgot your password? <Link to="/login/reset-password">Reset it here</Link></p>
                </form>                    
                    
                <div className="divider"><span>{this.t('Or')}</span></div>

                <p>{this.t('Don\'t have an account?')} <Link to="/create-account">{this.t('Sign up here')}</Link></p>

                {this.props.children}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.app.user,
        routing: state.routing
    };
}

export default connect(mapStateToProps)(injectIntl(Login));
