import React from 'react';
import { injectIntl } from 'react-intl';
import { browserHistory } from 'react-router';
import * as firebaseEvents from '../../utils/firebaseEvents';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class ResetPassword extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            emailError: null
        };
    }

    t(id, values) {
        return this.props.intl.formatMessage({id}, values);
    }

    validateEmail(email){
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    reset(e){
        e.preventDefault();

        var email = this.refs.email.children[1].children[0].value;
        if(this.validateEmail(email)){
            
            firebaseEvents.resetPassword(email, this.t.bind(this), this.close.bind(this));

        }
        else {
            this.setState({emailError: 'Incorrect email'});
        }

    }

    close(){
        browserHistory.push('/login');
    }

    render() {

        return (
            <Dialog className="ResetPassword" open={true} maxWidth="xs" onClose={this.close.bind(this)} >
                <DialogTitle>{this.t('Reset your password')}</DialogTitle>
                <DialogContent>
                    <form onSubmit={this.reset.bind(this)}>
                        <TextField type="text" className="email" ref="email" fullWidth={true} label={this.t('Your email')} error={!!(this.state.emailError)} helperText={this.state.emailError} />

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth={true}
                        >
                            {this.t('Reset your password')}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}


export default injectIntl(ResetPassword);
