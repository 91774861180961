import React from 'react';
import { connect } from 'react-redux';
import {injectIntl} from 'react-intl';
// import { Link, browserHistory } from 'react-router';
// import * as firebaseEvents from '../../utils/firebaseEvents';
import {bindActionCreators} from 'redux';
import * as appactions from '../App/AppActions';


class Payouts extends React.Component {

    render() {

        return (
            <div className="Payouts">
                <div className="box">
                    You have no Payout to date. <b>Request a payout on your Dashboard.</b>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.app.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(appactions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Payouts));
